import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from '../general/InputNumber';
import {Button, Form, Radio} from 'antd';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../constants/form';
import {Trans, t} from '@lingui/macro';
import VehiclesSelect from '../project/VehiclesSelect';
import { getInputOnChangeEventValue } from '../../lib/project';
import { downloadPdf } from '../../lib/url';
import MonthSelect from '../general/MonthSelect';
const FormItem = Form.Item;

/**
 * @dusan
 */


 class EventForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            template: 'month'
        };
    };

    onTypeChange = (ev) => {
        const val = getInputOnChangeEventValue(ev);
        this.setState({template: val});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, args) => {
            if (!err) {
                const pdfUrl = downloadPdf('vehicle', args.id_vehicle) + '&template=' + args.template + '&year=' + args.year + (args.month != null ? '&month=' + args.month : '') + '&close=' + args.close;
                window.open(pdfUrl, '_blank');
            }
        });
    };



    render() {
        const {onClose} = this.props;
        const {template} = this.state;
        const {getFieldDecorator} = this.props.form;
        const d = new Date();
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Vozidlo</Trans>}
            >
                {getFieldDecorator('id_vehicle', {rules: [mandatoryRule]})(
                    <VehiclesSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Typ výkazu</Trans>}
            >
                {getFieldDecorator('template', {rules: [mandatoryRule], initialValue: 'month'})(
                    <Radio.Group onChange={this.onTypeChange}>
                        <Radio.Button value="month"><Trans>Mesačný</Trans></Radio.Button>
                        <Radio.Button value="year"><Trans>Ročný</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Rok</Trans>}
            >
                {getFieldDecorator('year', {rules: [mandatoryRule], initialValue: d.getFullYear()})(
                    <InputNumber
                        min={2000}
                        step={1}
                    />
                )}
            </FormItem>
            { template == 'month' ? 
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Mesiac</Trans>}
                >
                    {getFieldDecorator('month', {rules: [mandatoryRule], initialValue: (d.getMonth() + 1)})(
                        <MonthSelect/>
                    )}
                </FormItem> : null
            }
            <FormItem
                {...formItemLayout}
                label={<Trans>Uzavrieť obdobie</Trans>}
            >
                {getFieldDecorator('close', {initialValue: 1})(
                    <Radio.Group>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit}>
                    <Trans>Generovať report</Trans>
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(EventForm);